/* Login.css */

.login-modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.login-modal.open {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mb-3 {
    margin-bottom: 1.3em;
}

.login-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
    text-align: center;
    position: relative;
    border-radius: 10px;
}

.login-modal-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.login-modal-close:hover,
.login-modal-close:focus {
    color: black;
    text-decoration: none;
}

input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #0056b3;
}
