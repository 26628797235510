.webhook-container {
    padding: 20px;
}

.webhook-table {
    width: 100%;
    border-collapse: collapse;
}

.webhook-table th,
.webhook-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

.webhook-table th {
    background-color: #f6f9fc;
    font-weight: 600;
}

/* Custom styles for the options */
option {
    padding: 10px;
    font-size: 16px;
    background-color: #fff;
    color: #333;
}

/* Custom styles for the selected option */
option:checked {
    background-color: #0070ba; /* Example color */
    color: #fff; /* Example color */
}