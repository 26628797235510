.payload-button {
    background-color: #0070ba;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.payload-button:hover {
    background-color: #005f92;
}